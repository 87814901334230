<template>
  <div class="view" v-if="$store.state.isLogin">
    <keep-alive>
      <transition :name="transitionName">
        <router-view></router-view>
      </transition>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'viewBox',
  data() {
    return {};
  },
  computed: {
    transitionName() {
      return localStorage.getItem('isMobie') == 'true' ? 'slide' : '';
    },
  },
};
</script>

<style scoped lang="less">
.view {
  height: 100%;
}
@T: 0.5s;
.slide-enter {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave {
  opacity: 1;
  transform: translateX(0);
}
.slide-enter-active,
.slide-leave-active {
  transition: all @T linear;
  position: fixed;
  top: 0;
}
</style>
